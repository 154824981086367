.Footer {
  margin-top: 50px;
  background-color: rgb(235, 235, 235);
  display: flex;
  flex-wrap: nowrap;
  height: 65px;
  width: 100%;
}
.Footer img {
  height: 60px;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.Footer p {
  margin-left: 10px;
  font-size: 10px;
  color: rgb(128, 127, 127);
  margin-bottom: 0px !important;
}

.Footer a:hover {
  text-decoration: none;
}
