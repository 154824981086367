/*
.App {
  margin: 0 auto;
}

.container-style {
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

body {
  margin: auto 0;
  background-repeat: no-repeat;
  background-position: 20px 20px;
  height: 100px;
  text-rendering: optimizeLegibility;
  font-family: "Lato", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: 400;
  color: #777;}

code {
  font-family: "proxima-nova", sans-serif;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 550;
  letter-spacing: 0.5px;
  color: #067eb3;
  font-size: 2.1em;
  line-height: 1.5;
  margin: 0 0 12px;
}
.Home h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #067eb3;
  font-size: 1.866em;
  line-height: 1.5;
  margin: 0 0 12px;
}

