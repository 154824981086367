.NavHeader {
  width: 99%;
}

.NavHeader .navbar-brand {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #067eb3;
  font-size: 2.4em;
  line-height: 1.5;
  margin: 50 0 12px;
}

.NavHeader a.navbar-brand {
  color: #067eb3;
}

.NavHeader a {
  color: #777;
  font-size: 18px;
}

.NavHeader a.nav-link {
  /* padding: 20px 2px 2px 24px !important; */
  padding-top: 5px;
  -moz-osx-font-smoothing: grayscale;
}

.NavHeader a:hover {
  color: #333;
  text-decoration: none;
}

.NavHeader .navbar-toggler {
  margin-top: 15px;
  margin-left: auto;
  border: 5px solid !important;
  border-radius: 4% !important;
  padding: 4px 6px !important;
  margin-bottom: 50px !important;
  color: rgb(211, 211, 211) !important;
}

.NavHeader button:focus {
  outline: none !important;
}

.NavHeader .portal-button {
  background-color: rgb(16, 159, 145);
  padding-left: 10px;
  padding-right: 10px;
  border: 4px solid rgb(211, 211, 211) !important;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgba(211, 211, 221, 0.5);
}
.NavHeader .portal-style {
  color: rgb(255, 255, 255) !important;
}

.NavHeader .portal-style:hover {
  color: rgb(211, 211, 221) !important;
}
